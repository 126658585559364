import React from 'react';
import {
  Added,
  Improved,
  Removed,
  List,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="July 2020" subnav="release-notes">
      <div id="July2020" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          Are the fireworks over yet? Nope, we have our July release to light.
          Apologies if it bothers your dogs. It's the last firework, we promise.
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              version="0.47.0"
              versionUrl="https://github.com/hudl/jarvis/releases/tag/v0.47.0"
              upgradeUrl="https://github.com/hudl/jarvis/wiki/Upgrading-to-Uniform-v4"
            />
            <div className={styles.changesList}>
              <Added>
                Brand new icons and categories, some of which even animate.
                <List>
                  <li>
                    <Link href="/components/icons/reactions/design">
                      Reactions
                    </Link>
                  </li>
                  <li>
                    {' '}
                    <Link href="/components/icons/logos/design">Logos</Link>
                  </li>
                </List>
              </Added>
              <Added>
                The <Link href="/components/themes/divider/code">divider</Link>{' '}
                component for all your dividing needs.
              </Added>
              <Improved>
                Our <Link href="/components/icons/code">icon</Link> component by
                making each its own individual component.
              </Improved>
              <Improved>
                Design for most components to embrace a more elite feel with
                sharper corners and sweeter animations.
              </Improved>
              <Improved>
                Our prop naming scheme.
                <List>
                  <li>Props are now more predictable.</li>
                  <li>
                    <code>bools</code> and <code>functions</code> are
                    standardized.
                  </li>
                </List>
              </Improved>
              <Improved>
                Our component showcase is now spiffier and contains all the
                different variations of our components to explore.
              </Improved>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Added>
                All the new information that goes along with our crisp, new
                release of React Native components.
              </Added>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
